import styled from "styled-components";

export const UlStyle = styled.ul`

    @media (min-width: 1000px){
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
  
`;